import axios from 'axios';
import moment from 'moment';
import { setLoading, clearLoading } from '../features/loading';

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if(token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const api = {
  dispatch: null,
  // domain: '/',
  domain: 'https://publicapi.livewelldorset.co.uk/',
  fetchGet: (url, cb) => {
    axios.get(`${api.domain}${url}`)
      .then(res => cb(null, res))
      .catch(cb);
  },

  fetchPost: (url, obj, cb) => {
    api.dispatch(setLoading(true));
    axios.post(`${api.domain}${url}`, obj)
      .then(res => {
        if (res) { api.dispatch(clearLoading()); }
        cb(null, res);
      })
      .catch((error) => {
        api.dispatch(clearLoading());
        cb(error, null);
      });
  },

  fetchPut: (url, obj, cb) => {
    api.dispatch(setLoading(true));
    axios.put(`${api.domain}${url}`, obj)
      .then(res => {
        if (res) { api.dispatch(clearLoading()); }
        cb(null, res);
      })
      .catch((error) => {
        api.dispatch(clearLoading());
      });
  },

  fetchDelete: (url, cb) => {
    api.dispatch(setLoading(true));
    axios.delete(`${api.domain}${url}`)
      .then(res => {
        if (res) { api.dispatch(clearLoading()); }
        cb(null, res);
      })
      .catch((error) => {
        api.dispatch(clearLoading());
      });
  },

  login: (username, dob, password, fin) => {
      const postDetails = {}
      postDetails.username = username;
      postDetails.password = password;
      postDetails.dob = moment(dob).format('YYYY-MM-DD');
      postDetails.grant_type = "password_client";
      postDetails.client_id = "4";
      postDetails.client_secret = "avcGa3QSg2HdtgXZd0FPBWXRUGFUZkKGFI0jPbpZ";
      postDetails.provider = "clients";
      postDetails.scope = "";

      api.fetchPost('oauth/token', postDetails, (err, res) => {
        if(err) {
          return fin(false, "Invalid username/password");
        }
        return fin(true, res.data);
      });
  },
  me: (cb) => api.fetchGet('mlw/me', cb),
  closeAccount: (cb) => api.fetchPut('mlw/close', {}, cb),
  updateProfile: (data, cb) => api.fetchPut('mlw/account', data, cb),
  updatePassword: (data, cb) => api.fetchPut('mlw/account/password', data, cb),
  plan: (cb) => api.fetchGet('mlw/plan', cb),
  // dates: (date, cb) => api.fetchGet(`mlw/calendar?date_filter=${date}`, cb),
  dates: (date, cb) => api.fetchGet(`mlw/calendar/timeslots/${date}`, cb),
  times: (date, cb) => api.fetchGet(`mlw/calendar/timeslots/${date}`, cb),
  updateBCT: ({bctId, score}, cb) => api.fetchPut(`mlw/plan/${bctId}`,{ progress: score }, cb),
  messages: (cb) => api.fetchGet('mlw/messages', cb),
  addMessage: (clientPlanId, message, cb) => api.fetchPost(`mlw/messages/${clientPlanId}`, { message }, cb),
  actions: (cb) => api.fetchGet('mlw/actions', cb),
  updateAction: (id, data, cb) => api.fetchPut(`mlw/actions/${id}`, data, cb),
  articles: (cb) => api.fetchGet('mlw/articles', cb),
  publicArticles: (cb) => api.fetchGet('articles', cb),
  updatePathways: (data, cb) => api.fetchPut(`mlw/pathways`, data, cb),
  notifications: (cb) => api.fetchGet('mlw/notifications', cb),
  dropdowns: (params, cb) => api.fetchGet('dropdowns?' + (Object.keys(params).map(key => key + '=' + params[key]).join('&')) ,cb),
  updateNotification: (id, cb) => api.fetchPut(`mlw/notifications/${id}`, {}, cb),
  sessions: (cb) => api.fetchGet('mlw/sessions', cb),
  cancelSession: (id,cb)  =>api.fetchDelete(`mlw/sessions/${id}`, cb),
  rescheduleSession: (id,date,cb)  =>api.fetchPut(`mlw/sessions/${id}`, {taskId:id,date:date}, cb),
  forgot: (data, cb) => api.fetchPost('clients/forgotpassword', data, cb),
  eligibility: (data, cb) => api.fetchPost('clients/check-client', data, cb),
  reset: (data, cb) => api.fetchPost(`clients/resetpassword/${data.token}`, data, cb),
  newClient: (data, cb) => api.fetchPost('register/newclient', data, cb),
  registerPassword: (data, cb) => api.fetchPost(`clients/register/${data.hash}/${data.client_id}`, data, cb),
  newAssessment: (data, cb) => api.fetchPost('assessment/new', data, cb),
  followupAssessment: (data, cb) => api.fetchPost('assessment/follow-up', data, cb),
  updateTimePreference: (data, cb) => api.fetchPost('contact/time-preference', data, cb),
  updateMethodPreference: (data, cb) => api.fetchPost('contact/method-preference', data, cb),
  preAssessment: (hash, cb) => api.fetchGet(`public-assessment${hash ? `/${hash}` : ''}`, cb),
  submitFeedback: (data, cb) => api.fetchPost('interaction/feedback', data, cb),
  submitHelpMessage: (data, cb) => api.fetchPost('interaction/help', data, cb),
  resendWelcomeEmail: (data, cb) => api.fetchPost('register/resend-welcome', data, cb),
}

export default api;
